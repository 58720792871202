<template>

    <div class="h-20 rounded-lg bg-filters mt-2 flex flex-col relative">
        <loader :loading="loading" />
        <div class="h-10 flex flex-row justify-between items-center px-4">
            <span class="text-font-gray font-semibold text-xs">Effectiveness ratio</span>
        </div>
        <div class="flex-1 flex flex-row">
            <div class="h-full w-9/12 flex flex-row justify-center items-center pl-4">
                <div class="h-2 w-full bg-body rounded-full overflow-hidden">
                    <div class="h-full bg-danger"
                         :style="'width:' + effectivenessRatio + '%; transition: all .3s;'">
                    </div>
                </div>
            </div>
            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                <span class="text-white font-semibold text-sm">{{ effectivenessRatio | numberFormat(2) }}%</span>
            </div>
        </div>
    </div>

</template>

<script>

import { state } from '@/store';

export default {
    name: 'PipelineEffectivenessMobile',
    data() {
        return {
            effectivenessRatio: 0,
            loading: false
        }
    },
    methods: {
        preload() {
            this.loading = true;
        },
        load() {
            this.axios.get('pipeline/effectiveness_ratio', {params: this.params}).then(response => {
                this.effectivenessRatio = response.data.data.percentage
                this.loading = false
            })
        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{sectorAmbiciona: this.$route.params.catId}}
            }
            return params
        },
    },
    watch:{
        params() { this.load() }
    },
    mounted() {
        this.preload();
        if(this.params) {
            this.load();
        }
    }
}
</script>